import { AT, BE, CA, DE, DK, FI, HU, IE, IT, JP, LU, NL, NO, PL, RU, SE, TR, UK, US } from 'constants/countries'
import { REGION_EU, REGION_RU, REGION_JP, REGION_US } from 'constants/regions'
import {
  GOODS_TYPES,
  COUNTRIES_WITH_DISTRIBUTION,
  COUNTRIES_WITHOUT_DNA,
  COUNTRIES_WITHOUT_SALES,
} from 'constants/sales'
import * as pages from 'constants/textBunchesNames'
import { enhanceRoute, enhanceModal } from 'utils/router'

import { black, grayOp06NonTransp } from './styles/theme/colors'

const DEPLOYMENT_REGION = process.env.region

export const home = enhanceRoute({
  basePath: '/:modal(reports)?', // have to be function to insert params (if we have it)
  dict: 'home', // attribute of section object in dictionary
  loadData: [pages.HOME],
  regions: [
    // array with deployment regions where it is possible to show the page
    REGION_EU,
    REGION_RU,
    REGION_JP,
    REGION_US,
  ],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  pagesGenerator: {
    pathToModule: '/Home',
  },
  priority: 1, // null to remove from sitemap
})

export const dna = enhanceRoute({
  basePath: '/dna',
  dict: 'dna',
  loadData: [pages.DNA_ABOUT],
  regions: [REGION_EU, REGION_RU, REGION_JP],
  hideForLocales: [...COUNTRIES_WITHOUT_DNA],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.dna],
  pagesGenerator: {
    pathToModule: '/DnaAbout',
  },
  priority: 0.9,
})

export const dnaAbout = enhanceRoute({
  basePath: '/dna',
  dict: 'dnaAbout',
  loadData: [pages.DNA_ABOUT],
  regions: [REGION_EU, REGION_RU, REGION_JP],
  hideForLocales: [...COUNTRIES_WITHOUT_DNA],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.dna],
  pagesGenerator: {
    pathToModule: '/DnaAbout',
  },
  priority: 0.9,
})

export const dnaResults = enhanceRoute({
  basePath: '/dna/results',
  dict: 'dnaResults',
  loadData: [pages.DNA_RESULTS],
  regions: [REGION_EU, REGION_RU, REGION_JP],
  hideForLocales: [...COUNTRIES_WITHOUT_DNA],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.dna],
  pagesGenerator: {
    pathToModule: '/DnaResults',
  },
  priority: 0.8,
})

export const dnaHowItWorks = enhanceRoute({
  basePath: '/dna/howitworks',
  dict: 'dnaHowItWorks',
  loadData: [pages.DNA_HOW_IT_WORKS],
  regions: [REGION_EU, REGION_RU, REGION_JP],
  hideForLocales: [...COUNTRIES_WITHOUT_DNA],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.dna],
  pagesGenerator: {
    pathToModule: '/DnaHowItWorks',
  },
  priority: 0.7,
})

export const wgs = enhanceRoute({
  basePath: '/wgs',
  dict: 'wgs',
  loadData: [pages.WGS_ABOUT],
  regions: [REGION_RU],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.wgs],
  pagesGenerator: {
    pathToModule: '/WgsAbout',
  },
  priority: 0.9,
  background: black,
})

export const wgsAbout = enhanceRoute({
  basePath: '/wgs',
  dict: 'wgsAbout',
  loadData: [pages.WGS_ABOUT],
  regions: [REGION_RU],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.wgs],
  pagesGenerator: {
    pathToModule: '/WgsAbout',
  },
  priority: 0.9,
  background: black,
})

export const wgsResults = enhanceRoute({
  basePath: '/wgs/results',
  dict: 'wgsResults',
  loadData: [pages.WGS_RESULTS],
  regions: [REGION_RU],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.wgs],
  pagesGenerator: {
    pathToModule: '/WgsResults',
  },
  priority: 0.8,
  background: black,
})

export const wgsHowItWorks = enhanceRoute({
  basePath: '/wgs/howitworks',
  dict: 'wgsHowItWorks',
  loadData: [pages.WGS_HOW_IT_WORKS],
  regions: [REGION_RU],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.wgs],
  pagesGenerator: {
    pathToModule: '/WgsHowItWorks',
  },
  priority: 0.7,
  background: black,
})

export const stories = enhanceRoute({
  basePath: '/dna/stories',
  dict: 'stories',
  loadData: [pages.STORIES],
  regions: [REGION_RU],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.dna],
  pagesGenerator: {
    pathToModule: '/Stories',
  },
  priority: 0.1,
})

export const biome = enhanceRoute({
  basePath: '/microbiome',
  dict: 'biome',
  loadData: [pages.BIOME_ABOUT],
  regions: [REGION_EU, REGION_RU, REGION_JP, REGION_US],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: true,
  isProductPage: true,
  productType: [GOODS_TYPES.biome],
  pagesGenerator: {
    pathToModule: '/BiomeAbout',
  },
  priority: 0.9,
})

export const biomeAbout = enhanceRoute({
  basePath: '/microbiome',
  dict: 'biomeAbout',
  loadData: [pages.BIOME_ABOUT],
  regions: [REGION_EU, REGION_RU, REGION_JP, REGION_US],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: true,
  isProductPage: true,
  productType: [GOODS_TYPES.biome],
  pagesGenerator: {
    pathToModule: '/BiomeAbout',
  },
  priority: 0.9,
})

export const biomeNutrition = enhanceRoute({
  basePath: '/microbiome/nutrition',
  dict: 'biomeNutrition',
  loadData: [pages.BIOME_NUTRITION],
  regions: [REGION_EU, REGION_RU, REGION_JP, REGION_US],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.biome],
  pagesGenerator: {
    pathToModule: '/BiomeNutrition',
  },
  priority: 0.8,
})

export const biomeResults = enhanceRoute({
  basePath: '/microbiome/results',
  dict: 'biomeResults',
  loadData: [pages.BIOME_RESULTS],
  regions: [REGION_EU, REGION_RU, REGION_JP, REGION_US],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.biome],
  pagesGenerator: {
    pathToModule: '/BiomeResults',
  },
  priority: 0.7,
})

export const biomeHowItWorks = enhanceRoute({
  basePath: '/microbiome/howitworks',
  dict: 'biomeHowItWorks',
  loadData: [pages.BIOME_HOW_IT_WORKS],
  regions: [REGION_EU, REGION_RU, REGION_JP, REGION_US],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: true,
  productType: [GOODS_TYPES.biome],
  pagesGenerator: {
    pathToModule: '/BiomeHowItWorks',
  },
  priority: 0.6,
})

export const about = enhanceRoute({
  basePath: '/about',
  dict: 'about',
  loadData: [pages.ABOUT],
  regions: [REGION_RU, REGION_JP, REGION_US],
  hideForLocales: [],
  hideInNavForLocales: [UK, RU, UK, DE, SE, NL, BE, LU, DK, FI, IE, AT, PL, CA, IT, HU, NO, TR, JP],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/About',
  },
  priority: 0.5,
})

export const contacts = enhanceRoute({
  basePath: '/contacts',
  dict: 'contacts',
  loadData: [pages.CONTACTS],
  regions: [REGION_EU, REGION_RU, REGION_JP, REGION_US],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/Contacts',
  },
  priority: 0.4,
})

export const faq = enhanceRoute({
  basePath: '/questions',
  dict: 'faq',
  loadData: [pages.FAQ],
  regions: [REGION_EU, REGION_RU, REGION_JP, REGION_US],
  hideForLocales: [...COUNTRIES_WITH_DISTRIBUTION],
  hideInNavForLocales: [RU],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/Faq',
  },
  priority: 0.6,
})

export const cart = enhanceRoute({
  basePath: '/cart',
  dict: 'cart',
  loadData: [pages.CART],
  regions: [REGION_EU, REGION_RU, REGION_JP, REGION_US],
  hideForLocales: [...COUNTRIES_WITH_DISTRIBUTION, ...COUNTRIES_WITHOUT_SALES],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/Cart',
  },
  priority: 0.8,
})

export const cartCheckout = enhanceRoute({
  basePath: '/cart/checkout',
  dict: 'cart',
  loadData: [pages.CART],
  regions: [REGION_EU, REGION_JP],
  hideForLocales: [...COUNTRIES_WITH_DISTRIBUTION, ...COUNTRIES_WITHOUT_SALES],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  isBlockedForDirectLink: true,
  isRedirectToCart: true,
  pagesGenerator: {
    pathToModule: '/CartCheckout',
  },
  priority: null,
})

export const cartPayment = enhanceRoute({
  basePath: '/cart/payment',
  dict: 'cart',
  loadData: [pages.CART],
  regions: [REGION_EU, REGION_JP],
  hideForLocales: [...COUNTRIES_WITH_DISTRIBUTION, ...COUNTRIES_WITHOUT_SALES],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  isBlockedForDirectLink: true,
  isRedirectToCart: true,
  pagesGenerator: {
    pathToModule: '/CartPayment',
  },
  priority: null,
})

export const cartSuccess = enhanceRoute({
  basePath: '/cart/success',
  dict: 'paymentSucceed',
  loadData: [],
  regions: [REGION_EU, REGION_RU, REGION_JP, REGION_US],
  hideForLocales: [...COUNTRIES_WITH_DISTRIBUTION, ...COUNTRIES_WITHOUT_SALES],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  isBlockedForDirectLink: true,
  pagesGenerator: {
    pathToModule: '/CartSuccess',
  },
  priority: null,
})

export const cartFail = enhanceRoute({
  basePath: '/cart/fail',
  dict: 'paymentFailed',
  loadData: [],
  regions: [REGION_EU, REGION_RU, REGION_JP, REGION_US],
  hideForLocales: [...COUNTRIES_WITH_DISTRIBUTION, ...COUNTRIES_WITHOUT_SALES],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  isBlockedForDirectLink: true,
  pagesGenerator: {
    pathToModule: '/CartFailure',
  },
  priority: null,
})

export const orderStatus = enhanceRoute({
  basePath: '/cart/status',
  dict: 'verifyOrder',
  loadData: [],
  regions: [REGION_RU],
  hideForLocales: [...COUNTRIES_WITH_DISTRIBUTION, ...COUNTRIES_WITHOUT_SALES],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/OrderStatus',
  },
  priority: null,
})

export const privacy = enhanceRoute({
  basePath: '/privacy',
  dict: 'privacy',
  loadData: [pages.PRIVACY_POLICY],
  regions: [REGION_EU, REGION_JP, REGION_US],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/PrivacyPolicy',
  },
  priority: 0.2,
  background: grayOp06NonTransp,
})

export const terms = enhanceRoute({
  basePath: '/rules',
  dict: 'terms',
  loadData: [pages.TERMS_AND_CONDITIONS],
  regions: [REGION_EU, REGION_JP, REGION_US],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/TermsAndConditions',
  },
  priority: 0.2,
  background: grayOp06NonTransp,
})

export const terms2017 = enhanceRoute({
  basePath: '/rules-2017',
  dict: 'terms2017',
  loadData: [pages.TERMS_AND_CONDITIONS2017],
  regions: [REGION_EU],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/TermsAndConditions2017',
  },
  priority: 0.2,
  noindex: true,
  background: grayOp06NonTransp,
})

export const terms2018 = enhanceRoute({
  basePath: '/rules-2018',
  dict: 'terms2018',
  loadData: [pages.TERMS_AND_CONDITIONS2018],
  regions: [REGION_EU],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/TermsAndConditions2018',
  },
  priority: 0.2,
  noindex: true,
  background: grayOp06NonTransp,
})

export const terms2019_early = enhanceRoute({
  basePath: '/rules-early-2019',
  dict: 'terms2019early',
  loadData: [pages.TERMS_AND_CONDITIONS2019_EARLY],
  regions: [REGION_EU],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/TermsAndConditions2019Early',
  },
  priority: 0.3,
  noindex: true,
  background: grayOp06NonTransp,
})

export const terms_b2b = enhanceRoute({
  basePath: '/b2b-terms',
  dict: 'terms_b2b',
  loadData: [pages.TERMS_AND_CONDITIONS_B2B],
  regions: [REGION_EU, REGION_JP],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/TermsAndConditionsB2B',
  },
  priority: 0.2,
  noindex: true,
  background: grayOp06NonTransp,
})

export const terms_gc = enhanceRoute({
  basePath: '/rules-gc',
  dict: 'terms_gc',
  loadData: [pages.TERMS_AND_CONDITIONS_GC],
  regions: [REGION_EU],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/TermsAndConditionsGC',
  },
  priority: 0.2,
  noindex: true,
  background: grayOp06NonTransp,
})

export const cookiePolicy = enhanceRoute({
  basePath: '/cookie-policy',
  dict: 'cookie',
  loadData: [pages.COOKIE_POLICY],
  regions: [REGION_EU, REGION_JP, REGION_US],
  hideForLocales: [],
  hideInNavForLocales: [],
  isOuterLink: false,
  isProductPage: false,
  pagesGenerator: {
    pathToModule: '/CookiePolicy',
  },
  priority: 0.2,
  background: grayOp06NonTransp,
})

export const modalTraitsList = enhanceModal({
  name: 'reports',
  parent: home,
})

/*
 * Possible structure
 * [
 *   {
 *     route: // route config object
 *     ?isHiddenOnDesktop: // is link hidden on tablet/desktop
 *     ?hideForLocales: [ // Locales ],
 *     ?hideInNavForLocales: [], // hide route for specific locale
 *     ?isOuterLink: false,
 *     ?isProductPage: false,
 *     ?items: [ // routes for 2nd lvl navigation
 *        {
 *          route: // route config object
 *        }
 *     ],
 *   },
 *   ...
 * ]
 */

export const partners = {
  basePath: 'https://partners.atlasbiomed.com/',
  dict: 'partners',
  loadData: [],
  regions: [REGION_EU, REGION_US],
  hideForLocales: [TR],
  hideInNavForLocales: [TR],
  isOuterLink: true,
  isProductPage: false,
  priority: 0.9,
  link: () => 'https://partners.atlasbiomed.com/',
  as: () => 'https://partners.atlasbiomed.com/',
}

export const dataInterpretation = {
  basePath: 'https://upload.atlas.ru',
  dict: 'dataInterpretation',
  regions: [REGION_RU],
  isOuterLink: true,
  isProductPage: false,
  priority: 0.9,
  link: () => 'https://upload.atlas.ru',
  as: () => 'https://upload.atlas.ru',
}

export const mainMenu = [
  { route: home, isHiddenOnDesktop: true },
  {
    route: dna,
    items: [
      { route: dnaAbout },
      { route: dnaResults },
      { route: dnaHowItWorks },
      { route: stories },
    ],
  },
  {
    route: biome,
    items: [
      { route: biomeAbout },
      { route: biomeResults },
      { route: biomeHowItWorks },
      { route: biomeNutrition },
    ],
  },
  {
    route: wgs,
    items: [
      { route: wgsAbout },
      { route: wgsResults },
      { route: wgsHowItWorks },
    ],
  },
  {
    route: dataInterpretation,
  },
  { route: about },
  { route: partners },
  { route: faq },
  { route: cart, isHiddenOnDesktop: true },
]

export const footerMenuCol1 = [about, contacts, faq].filter(item =>
  item.regions.includes(DEPLOYMENT_REGION),
)
export const footerMenuCol2 = [privacy, terms, cookiePolicy].filter(item =>
  item.regions.includes(DEPLOYMENT_REGION),
)

// Have to be flat config of routes (one level)
export const routerConfig = [
  home,
  dna,
  dnaAbout,
  dnaResults,
  dnaHowItWorks,
  stories,
  // science,
  biome,
  biomeAbout,
  biomeResults,
  biomeNutrition,
  biomeHowItWorks,
  wgs,
  wgsAbout,
  wgsResults,
  wgsHowItWorks,
  about,
  contacts,
  faq,
  cart,
  cartCheckout,
  cartPayment,
  cartSuccess,
  cartFail,
  privacy,
  terms,
  terms2019_early,
  terms2018,
  terms2017,
  terms_b2b,
  terms_gc,
  cookiePolicy,
  orderStatus,
]
