import { createAction } from 'redux-actions'

import {
  PAGE_REQUEST,
  CART_INIT,
  CART_ADD_AMOUNT,
  CART_PROMO_SEND,
  CART_PROMO_RESET,
  CART_EUROPE_COLLECT_REQUEST,
  CART_COLLECT_RESET,
  CART_EUROPE_PAYMENT_REQUEST,
  CART_RUSSIA_COLLECT_REQUEST,
  CART_RUSSIA_PAYMENT_REQUEST,
  CART_PERSONAL_INFO_CHANGE,
  CART_CLEAR_GOODS,
  CART_RESET,
  USER_PROFILE_REQUEST,
  SAVE_ORDER_ID,
  CART_DELIVERY_TYPE_CHANGE,
  CART_PAGE_LEAVING,
} from 'constants/actionTypes'

/**
 * Action to get localization for page
 *
 * @param page {String} — code of page
 * @param locale {String} — code of locale
 * @type {function}
 */
export const getPage = createAction(PAGE_REQUEST, ({ page, locale }) => ({
  page,
  locale,
}))

/**
 * Action to initialize cart based on Storage and URL params
 *
 * @type {function}
 */
export const initCart = createAction(CART_INIT)

/**
 * Action to add some amount of products to cart
 *
 * @param productType {string} — type of product to add (code)
 * @param amount {number} — amount of products to add
 * @param isSubscription {boolean} — is it subscription
 * @type {function}
 */
export const addAmount = createAction(
  CART_ADD_AMOUNT,
  ({ productType, amount, isSubscription }) => ({
    productType,
    amount,
    isSubscription,
  }),
)

/**
 * Action to send promocode
 *
 * @param promo {string} — name of promocode
 * @type {function}
 */
export const sendPromo = createAction(
  CART_PROMO_SEND,
  ({ promocode, locale }) => ({
    promocode,
    locale,
  }),
)

/**
 * Action to reset promocode when close input
 *
 * @type {function}
 */
export const resetPromo = createAction(CART_PROMO_RESET)

/**
 * Action to collect order data for europe
 *
 * @type {function}
 */
export const europeCollectData = createAction(CART_EUROPE_COLLECT_REQUEST)

/**
 * Action to reset collect order data
 *
 * @type {function}
 */
export const collectDataReset = createAction(CART_COLLECT_RESET)
/**
 * Action to create payment for europe order
 *
 * @param stripe {object} — object of Stripe payment system (to work with tokens)
 * @type {function}
 */
export const europePayment = createAction(
  CART_EUROPE_PAYMENT_REQUEST,
  ({ stripe }) => ({
    stripe,
  }),
)

/**
 * Action to collect order data for russia
 *
 * @type {function}
 */
export const russiaCollectData = createAction(
  CART_RUSSIA_COLLECT_REQUEST,
  () => ({}),
)

/**
 * Action to initialize russia order
 *
 * @param yandex {object} — object with yandex data
 * @type {function}
 */
export const russiaPayment = createAction(
  CART_RUSSIA_PAYMENT_REQUEST,
  ({ yandex }) => ({
    yandex,
  }),
)

/**
 * Action to save personal info from order form
 *
 * @param values {object} — object of personal form info
 * @type {function}
 */
export const personalInfoChange = createAction(
  CART_PERSONAL_INFO_CHANGE,
  ({ personalInfo }) => ({
    personalInfo,
  }),
)

/**
 * Action when user leaving cart page
 *
 * @type {function}
 */
export const cartPageLeaving = createAction(CART_PAGE_LEAVING)

/**
 * Action to clear all ordered goods (after successful order)
 *
 * @type {function}
 */

export const clearGoods = createAction(CART_CLEAR_GOODS)

/**
 * Action to reset cart (after successful order)
 * erase all information about previous orders, created during user session
 *
 * @type {function}
 */
export const resetCart = createAction(CART_RESET)

/**
 * Action to get user profile
 *
 * @type {function}
 */
export const getUserProfile = createAction(USER_PROFILE_REQUEST)

/**
 * Action to get order id from url
 *
 * @type {function}
 */
export const saveOrderIdOrError = createAction(
  SAVE_ORDER_ID,
  ({ orderId, error }) => ({
    orderId,
    error,
  }),
)

/**
 * Action to get delivery type for Russia
 *
 * @type {function}
 */
export const changeDeliveryType = createAction(
  CART_DELIVERY_TYPE_CHANGE,
  deliveryType => deliveryType,
)
